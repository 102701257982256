import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import ExcelUpload from "../../../utils/components/excelUpload";
import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import { BASE_URL } from "../../../global";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ImportExcelModal = ({
  fixedColumns,
  setReadingRows,
  settings,
}) => {
  const [open, setOpen] = React.useState(false);
  const [excelArray, setExcelArray] = React.useState([]);
  const [instruments, setInstruments] = React.useState([]);
  const [rejectedData, setRejectedData] = React.useState([]);
  const [remainingData, setRemainingData] = React.useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchInstrumentsData = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "instruments?_fields=id,instrumentName&_where=(status,eq,1)")
      .then((res) => {
        setInstruments(res.data);
      })
      .catch((err) => console.log("instruments data error: ", err));
  };

  const handleSubmit = () => {
    setReadingRows((prev) => [...prev, ...remainingData]);
    handleClose();
  };

  useEffect(() => {
    fetchInstrumentsData();
  }, []);

  useEffect(() => {
    let extrsKeys = [];
    if (settings.srfInstrumentsAdditionalColumns?.value) {
      extrsKeys = settings.srfInstrumentsAdditionalColumns?.value?.split(",");
    }
    const allowedKeys = fixedColumns.map((column) => column.key);

    const fixedColumnsData = excelArray.map((item) => {
      const filteredItem = {};
      allowedKeys.forEach((key) => {
        filteredItem[key] = item.hasOwnProperty(key) ? item[key] : null;
      });

      const extraColumns = {};
      extrsKeys.forEach((key) => {
        extraColumns[key.trim()] = item.hasOwnProperty(key.trim())
          ? item[key.trim()]
          : "";
      });

      filteredItem["extraColumns"] = extraColumns;
      return filteredItem;
    });

    const rejectedDataArray = [];
    const remainingDataArray = [];

    for (const fixedColumnsObj of fixedColumnsData) {
      const foundInstrument = instruments.find(
        (instrument) => instrument.id === fixedColumnsObj.instrumentId
      );

      if (!foundInstrument) {
        const rejectedObj = {
          ...fixedColumnsObj,
          rejectionStatus: "Instrument is not found in Instrument database",
        };
        rejectedDataArray.push(rejectedObj);
      } else {
        remainingDataArray.push(fixedColumnsObj);
      }
    }

    setRejectedData(rejectedDataArray);
    setRemainingData(remainingDataArray);
  }, [excelArray, fixedColumns, instruments]);

  return (
    <div>
      <Button
        id="ducMaster_list_uploadexcel"
        variant="contained"
        onClick={handleOpen}
        size="small"
      >
        Upload Excel File
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ textAlign: "center" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Upload Excel for SRF
          </Typography>

          <Typography
            sx={{ textAlign: "right", fontSize: "15px" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {`Rejected Data:${rejectedData.length}, Accepted Data:${remainingData.length}`}
          </Typography>

          <Box style={{ margin: "auto" }}>
            <ExcelUpload
              setExcelArray={setExcelArray}
              buttonName={"Select Excel File"}
            />
          </Box>

          <Typography
            sx={{ textAlign: "center", margin: "15px 0px" }}
            id="modal-modal-title"
            variant="h6"
            component="h6"
          >
            Rejected Excel Data
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="Rejected Data Table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr.</TableCell>
                  <TableCell>Instrument ID</TableCell>
                  <TableCell>Serial Number</TableCell>
                  <TableCell>DUCID</TableCell>
                  <TableCell>Rejection Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rejectedData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.instrumentId}</TableCell>
                    <TableCell>{row.serialNumber}</TableCell>
                    <TableCell>{row.DUCID}</TableCell>
                    <TableCell>{row.rejectionStatus}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            style={{
              margin: "20px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button variant="contained" component="span" onClick={handleClose}>
              Cancel
            </Button>

            <Button
              variant="contained"
              component="span"
              disabled={!remainingData.length > 0}
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
