import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Toolbar,
  TextField,
  Autocomplete,
  Modal,
  Grid,
  Box,
  useMediaQuery,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import { Table } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { unitListGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";
// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");



const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },

  {
    id: "disciplineId",
    label: "Discipline",
    align: "left",
    minWidth: 40,
  },
  {
    id: "symbol",
    label: "Symbol",
    align: "left",
    minWidth: 40,
  },
];

function createData(id, disciplineId, symbol) {
  return {
    id,
    disciplineId,
    symbol,
  };
}

export default function UnitList() {
  const unitListGuides = unitListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [page, setPage] = React.useState(0);
  const [searchBy, setSearchBy] = React.useState("Symbol");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [unitList, setUnitList] = React.useState([]);
  const [unitData, setUnitData] = React.useState([]);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `unit/${id}`, jsonData)
      .then((res) => {
        toast("Unit was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((err) => {
        toast.error(<h6 onClick={() => openErrorModal(err.message, "submitSiteDelete" ,"Unit List")}><u>Error: {err.message}</u></h6>);
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getUnitList = (event) => {
    let url = BASE_URL;
    const payload1 = {
      query:
        "SELECT unit.*, discipline.name AS disciplineId FROM unit JOIN discipline ON unit.disciplineId = discipline.id WHERE unit.status <> -1 ORDER BY unit.id DESC",
    };
    axiosWithToken
      .post(url + "dynamic", payload1)
      // .get(
      //   url +
      //     "xjoin?_join=units.unit,_j,disc.discipline&_on1=(units.disciplineId,eq,disc.id)&_fields=units.symbol,disc.name,units.id&_where=(units.status,eq,1)"
      // )
      .then((res) => {
        console.log("res", res.data);
        setUnitList(res.data);
        initializeDataTable();
      })
      .catch((err) => {
        console.log(err);
        toast.error(<h6 onClick={() => openErrorModal(err.message, "getUnitList" ,"Unit List")}><u>Error: {err.message}</u></h6>);
      });
  };

  const search = async () => {
    let colName = {
      "Discipline Name": "discipline.name",
      "Symbol": "symbol",
    }[searchBy];
    let query = {
      query: `SELECT unit.*, discipline.name AS disciplineId FROM unit JOIN discipline ON unit.disciplineId = discipline.id WHERE ${colName} LIKE '%${searchKey}%'`,
    };
    try {
      const response = await axiosWithToken.post(BASE_URL + `dynamic`, query);
      console.log(response.data);
      setUnitList(response.data);
    } catch (err) {
      console.error(err);
      let trace = new Error().stack;
      toast.error(<h6 onClick={() => openErrorModal(err.message, trace ,"Unit List")}><u>Error: {err.message}</u></h6>);
    }
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  const initializeUnitData = () => {
    var rowDaata = [];
    for (let i = 0; i < unitList.length; i++) {
      rowDaata.push(
        createData(
          unitList[i].units_id,
          unitList[i].disc_name,
          unitList[i].units_symbol
        )
      );
    }
    setUnitData(rowDaata);
  };

  useEffect(() => {
    getUnitList();
  }, []);

  useEffect(() => {
    initializeUnitData();
  }, [unitList]);

  const filterSection = () => (
    <Grid
      container
      spacing={2}
      // justifyContent="flex-end"
      alignItems="center"
      style={{ padding: "5px 5px", marginBottom: "2px" }}
    >
      <Grid item xs={12} sm={5} md={2} lg={2} id="client_list_searchby">
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
          size="small"
          value={searchBy}
          options={[
            { key: "instrumentId", label: "Discipline Name" },
            { key: "symbol", label: "Symbol" }
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <TextField
          id="client_list_searchbytext"
          label={"enter " + searchBy}
          size="small"
          value={searchKey}
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={1} lg={1} textAlign={"left"}>
        <Button
          id="client_list_searchbtn"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            search();
            handleFilterClose();
          }}
        >
          <SearchIcon />
        </Button>
      </Grid>
    </Grid>
  );
  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  return (
    <Box sx={{ margin: "0px 5px", marginTop: "-0.8rem" }}>
      {filterModal()}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar style={{ padding: "0px" }}>
          <Button
            id="unit_list_createbtn"
            variant="contained"
            size="small"
            component={Link}
            to="/master/unit/createUnit"
            onClick={() => {
              {
              }
            }}
          >
            <b>create UNIT</b>
          </Button>
        </Toolbar>
        <Box sx={{ display: "flex", gap: "0rem" }}>
          <Button
            style={{ display: isMobile ? "block" : "none" }}
            onClick={handleFilterOpen}
          >
            <FilterAltIcon />
          </Button>
          <Tooltip title="User Guide" placement="top-start">
            <Button
              onClick={() => {
                unitListGuides.drive();
              }}
            >
              <HelpIcon />
            </Button>
          </Tooltip>
        </Box>
      </div>
      {!isMobile && filterSection()}
      <TableContainer sx={{ maxHeight: isMobile ? "80vh" : "65vh" }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="unit_list_table"
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unitList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      let value = row[column.id];

                      if (column.id == "id") {
                        value = page * rowsPerPage + index + 1;
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell>
                      <ButtonGroup size="small" aria-label="small button group">
                        <Tooltip title="Edit Unit" placement="top-start">
                          <Button
                            id="unit_list_edit"
                            component={Link}
                            to={`/master/unit/EditUnit/${row.id}`}
                          >
                            <PreviewIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete Unit" placement="top-start">
                          <Button
                            id="unit_list_delete"
                            onClick={(e) => {
                              if (
                                window.confirm("Really want to delete Unit?")
                              ) {
                                submitSiteDelete(row.id);
                              }
                            }}
                          >
                            <DeleteIcon style={{ color: "#dc3545" }} />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={unitList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </Box>
  );
}
