import React, { useContext, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ClassicTable } from "../../../utils/components/Styles";
import moment from "moment";
import { DatasheetContext } from "./datasheet.context.js";

export default function DetailsOfCalStandards(props) {
  const {
    state: {
      standardMasterArray,
      selectedStandardIds,
      selectedStandardMasters,
    },
    dispatch,
  } = useContext(DatasheetContext);

  //validation Function
  const validateStandards = (type) => {
    let counter = 1;
    if (type === 1) {
      let expiredStandards = [];
      standardMasterArray.map((standard) => {
        if (moment().isAfter(standard.validUpto)) {
          expiredStandards.push(
            `${counter}. ${standard.stId} (${standard.standardName})`
          );
          counter = counter + 1;
        }
      });
      return expiredStandards;
    }
  };

  const updateSelectedStandardMasters = (selectedStandardIds) => {
    // fun: assign standard master data from standardMasterArray to selectedStandardMasters based on selectedStandardIds
    let _selectedStandardIds = selectedStandardIds
      .map((id) => Number(id))
      .filter((id) => id > 0);

    if (!standardMasterArray || standardMasterArray?.length === 0) return;

    let tempSelectedStandardMasters = [];
    _selectedStandardIds.forEach((id) => {
      let standardMaster = standardMasterArray.find(
        (standard) => standard.id == id
      );
      if (standardMaster) tempSelectedStandardMasters.push(standardMaster);
    });
    // set selectedStandardMasters
    dispatch({
      field: "selectedStandardMasters",
      value: [...tempSelectedStandardMasters],
    });
  };

  useEffect(() => {
    updateSelectedStandardMasters(selectedStandardIds);
  }, [selectedStandardIds, standardMasterArray]);

  useEffect(() => {
    if (standardMasterArray.length > 0) {
      let expiredStandards = validateStandards(1);
      if (expiredStandards.length > 0) {
        window.alert(
          `Below standards are expired:\n${expiredStandards.join(
            "\n"
          )} \n\nPlease recalibrate these standards and edit datasheet later!`
        );
      }
    }
  }, [standardMasterArray]);

  const parseRange = (rangeVal) => {
    if (!rangeVal) {
      return "";
    }

    const tmp = rangeVal.split("||").map((range) => {
      const values = range.split("|").map((value) => value.trim());
      const firstValueParts = values[0].split("#");
      const firstValue = firstValueParts[0].trim();
      const firstUnit = firstValueParts[1] ? firstValueParts[1].trim() : "";

      const secondValueParts = values[1]
        ? values[1].split("#")
        : [undefined, undefined];
      const secondValue = secondValueParts[0] ? secondValueParts[0].trim() : "";
      const secondUnit = secondValueParts[1] ? secondValueParts[1].trim() : "";

      let result = "";

      if (firstValue) {
        result += firstValue;
        if (firstUnit && !secondValue) {
          result += ` ${firstUnit}`;
        }
      }

      if (firstValue && secondValue) {
        result += " to ";
      }

      if (secondValue) {
        result += `${secondValue} ${secondUnit || ""}`;
      }

      return result;
    });

    return tmp.join(" , ");
  };

  return (
    <div>
      <Typography variant={props.isPrinting ? "body1" : "h6"} component="div" sx={{ mb: props.isPrinting ?0: 2,textAlign:"center" }}>
        Details of Calibration Standard Used
      </Typography>
      <div style={{ width: "100%", overflow: "auto" }}>
      <ClassicTable>
       <Table sx={{ minWidth: 660 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {selectedStandardMasters &&
              selectedStandardMasters.length>0 && <TableCell>Sr. No.</TableCell>}
            {selectedStandardMasters &&
              selectedStandardMasters.some(row => row.standardName) && <TableCell>Name of Standard</TableCell>}
            {selectedStandardMasters &&
              selectedStandardMasters.some(row => row.masterrange) && <TableCell>Range</TableCell>}
            {selectedStandardMasters &&
              selectedStandardMasters.some(row => row.masteraccuracy) && <TableCell>Accuracy</TableCell>}
            {selectedStandardMasters &&
              selectedStandardMasters.some(row => row.masterleastcount) && <TableCell>Least Count</TableCell>}
            {selectedStandardMasters &&
              selectedStandardMasters.some(row => row.make) && <TableCell>Make/Model</TableCell>}
            {selectedStandardMasters &&
              selectedStandardMasters.some(row => row.certificateNumber) && <TableCell>Certificate No.</TableCell>}
            {selectedStandardMasters &&
              selectedStandardMasters.some(row => row.traceability) && <TableCell>Tracability</TableCell>}
            {selectedStandardMasters &&
              selectedStandardMasters.some(row => row.calDate) && <TableCell>Cal.dt</TableCell>}
            {selectedStandardMasters &&
              selectedStandardMasters.some(row => row.validUpto) && <TableCell>Due Date</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedStandardMasters &&
            selectedStandardMasters?.map((row, index) => (
              <TableRow key={"standard-row-" + index}>
                {selectedStandardMasters.length>0 && (
                  <TableCell>{index + 1}</TableCell>
                )}
                {selectedStandardMasters.some(row => row.standardName) && (
                  <TableCell
                    style={{
                      color: `${moment().isAfter(row?.validUpto) ? "red" : ""}`,
                    }}
                  >
                    {row.standardName}
                  </TableCell>
                )}
                {selectedStandardMasters.some(row => row.masterrange) && <TableCell>{parseRange(row.masterrange)}</TableCell>}
                {selectedStandardMasters.some(row => row.masteraccuracy) && <TableCell>{parseRange(row.masteraccuracy)}</TableCell>}
                {selectedStandardMasters.some(row => row.masterleastcount) && <TableCell>{parseRange(row.masterleastcount)}</TableCell>}
                {selectedStandardMasters.some(row => row.make) && <TableCell>{row.make}</TableCell>}
                {selectedStandardMasters.some(row => row.certificateNumber) && <TableCell>{row.certificateNumber}</TableCell>}
                {selectedStandardMasters.some(row => row.traceability) && <TableCell>{row.traceability}</TableCell>}
                {selectedStandardMasters.some(row => row.calDate) && <TableCell>{moment(row.calDate).format("DD-MM-YYYY")}</TableCell>}
                {selectedStandardMasters.some(row => row.validUpto) && <TableCell>{moment(row.validUpto).format("DD-MM-YYYY")}</TableCell>}
              </TableRow>
            ))}
        </TableBody>
      </Table>
 
      </ClassicTable>
      </div>
    </div>
  );
}
